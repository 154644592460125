import ArrowDown from "../../assets/arrowDown";
import ArrowUp from "../../assets/arrowUp";
import BankTransferIcon from "../../assets/bankTransferIcon";
import Bars from "../../assets/bars";
import BoxOpen from "../../assets/boxOpen";
import CaretCircleDown from "../../assets/caretCircleDown";
import Check from "../../assets/check";
import CheckCircle from "../../assets/checkCircle";
import Clipboard from "../../assets/clipboard";
import Cog from "../../assets/cog";
import DollarSign from "../../assets/dollarSign";
import EditIcon from "../../assets/edit";
import ExclamationCirlce from "../../assets/exclamationCircle";
import ExclamationSquare from "../../assets/exclamationSquare";
import ExternalLink from "../../assets/externalLink";
import FileUpload from "../../assets/fileUpload";
import HomeIcon from "../../assets/home";
import HourglassEnd from "../../assets/hourglassEnd";
import HourglassHalf from "../../assets/hourglassHalf";
import HourglassStart from "../../assets/hourglassStart";
import OnlinePaymentIcon from "../../assets/onlinePaymentIcon";
import Pencil from "../../assets/pencil";
import PeopleCarry from "../../assets/peopleCarry";
import PhotoVideo from "../../assets/photoVideo";
import PlaneArrival from "../../assets/planeArrival";
import PlusIcon from "../../assets/plus";
import Print from "../../assets/print";
import Save from "../../assets/save";
import SearchIcon from "../../assets/search";
import SearchPlus from "../../assets/searchPlus";
import ShippingFast from "../../assets/shippingFast";
import SignOutAlt from "../../assets/signOutAlt";
import Star from "../../assets/star";
import TimesRed from "../../assets/timesRed";
import TimesCircle from "../../assets/timesCircle";
import TimesWhite from "../../assets/timesWhite";
import ShoppingCartWhite from "../../assets/shoppingCartWhite";
import ShoppingCartBlack from "../../assets/shoppingCartBlack";
import CreditCard from "../../assets/creditCard";
import TrashRed from "../../assets/trashRed";
import TrashWhite from "../../assets/trashWhite";
import StarWhite from "../../assets/starWhite";
import Times from "../../assets/times";
import MailOpened from "../../assets/mailOpened";

export const icons = [
  'ArrowUp',
  'ArrowDown',
  'BankTransferIcon',
  'Bars',
  'BoxOpen',
  'CaretCircleDown',
  'Check',
  'CheckCircle',
  'Clipboard',
  'Cog',
  'CreditCard',
  'DollarSign',
  'EditIcon',
  'ExclamationCirlce',
  'ExclamationSquare',
  'ExternalLink',
  'MailOpened',
  'FileUpload',
  'HomeIcon',
  'HourglassEnd',
  'HourglassHalf',
  'HourglassStart',
  'OnlinePaymentIcon',
  'Pencil',
  'PeopleCarry',
  'PhotoVideo',
  'PlaneArrival',
  'PlusIcon',
  'Print',
  'Save',
  'SearchIcon',
  'SearchPlus',
  'ShippingFast',
  'ShoppingCartWhite',
  'ShoppingCartBlack',
  'SignOutAlt',
  'StarBlack',
  'StarWhite',
  'Times',
  'TimesRed',
  'TimesWhite',
  'TimesCircle',
  'TrashRed',
  'TrashWhite',
] as const;

export type IconNames = typeof icons[number];


export const SvgIcons = {
  'ArrowUp': ArrowUp,
  'ArrowDown': ArrowDown,
  'BankTransferIcon': BankTransferIcon,
  'Bars': Bars,
  'BoxOpen': BoxOpen,
  'CaretCircleDown': CaretCircleDown,
  'Check': Check,
  'CheckCircle': CheckCircle,
  'Clipboard': Clipboard,
  'Cog': Cog,
  'CreditCard': CreditCard,
  'DollarSign': DollarSign,
  'EditIcon': EditIcon,
  'ExclamationCirlce': ExclamationCirlce,
  'ExclamationSquare': ExclamationSquare,
  'ExternalLink': ExternalLink,
  'MailOpened': MailOpened,
  'FileUpload': FileUpload,
  'HomeIcon': HomeIcon,
  'HourglassEnd': HourglassEnd,
  'HourglassHalf': HourglassHalf,
  'HourglassStart': HourglassStart,
  'OnlinePaymentIcon': OnlinePaymentIcon,
  'Pencil': Pencil,
  'PeopleCarry': PeopleCarry,
  'PhotoVideo': PhotoVideo,
  'PlaneArrival': PlaneArrival,
  'PlusIcon': PlusIcon,
  'Print': Print,
  'Save': Save,
  'SearchIcon': SearchIcon,
  'SearchPlus': SearchPlus,
  'ShippingFast': ShippingFast,
  'ShoppingCartWhite': ShoppingCartWhite,
  'ShoppingCartBlack': ShoppingCartBlack,
  'SignOutAlt': SignOutAlt,
  'StarBlack': Star,
  'StarWhite': StarWhite,
  'Times': Times,
  'TimesRed': TimesRed,
  'TimesWhite': TimesWhite,
  'TimesCircle': TimesCircle,
  'TrashRed': TrashRed,
  'TrashWhite': TrashWhite,
};
