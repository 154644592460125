import Modal from "../Modal/Modal";
import { StyledButton } from "../styled/styled-components";
import EmailTransferInfo from "./EmailTransferInfo";

interface IProps {
  onCloseModal?: () => void;
  closeButtonName?: string;
}

const EmailTransferInfoModal = (props: IProps) => {
  const { onCloseModal, closeButtonName = "Close" } = props;
  return (
    <Modal>
      <div className="bank-transfer-info-modal">
        <EmailTransferInfo />
        <div className="actions">
          {onCloseModal && (
            <StyledButton onClick={onCloseModal}>
              {closeButtonName}
            </StyledButton>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default EmailTransferInfoModal;
