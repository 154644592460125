import { useRef, useState } from "react";
import "../../styles/Tooltip.scss";
import cx from "classnames";

type Props = {
  content: string;
  children?: React.ReactNode;
  position?: "left" | "bottom" |"top" | "right";
  tooltipClassName?: string;
};
const Tooltip = ({ children, content, position = 'top', tooltipClassName }: Props) => {
  const [visible, setVisible] = useState(false);
  const tooltipRef = useRef<HTMLDivElement | null>(null);
  return (
    <div
      className="tooltip-wrapper"
      onMouseEnter={() => setVisible(true)}
      onMouseLeave={() => setVisible(false)}
    >
      {children}
      {visible && (
        <div className={cx("tooltip", position, tooltipClassName)} ref={tooltipRef}>
          {content}
        </div>
      )}
    </div>
  );
};

export default Tooltip;
