import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import AddressBook from "../../../../components/Address/AddressBook/AddressBook";
import Alert from "../../../../components/Alert/Alert";
import { StyledButton } from "../../../../components/styled/styled-components";
import { Address } from "../../../../interfaces/interfaces";
import {
  FreightOption,
  ShippingAddressType
} from "../../../../interfaces/types";
import {
  setCurrentOrderShipAddress,
  updateFreightOption
} from "../../../../redux/actions/orders";
import { CurrentOrder } from "../../../../redux/reducers/orders";
import { RootState } from "../../../../redux/store";
import AddressOptions from "./AddressOptions/AddressOptions";
import NewAddress from "./AddressOptions/NewAddress/NewAddress";
import PickUp from "./AddressOptions/PickUp/PickUp";
import PrimaryAddress from "./AddressOptions/PrimaryAddress/PrimaryAddress";
import FreightOptions from "./FreightOptions";
import { albertaRates, bcRates, otherRatesGround } from "./shipping-rates";

function ShippingOptionsCA() {
  const FASHION_BIZ_PICK_UP_ADDRESS =
    "7898 North Fraser Way, Burnaby BC V5J0C7";
  const [shippingAddressType, setShippingAddressType] =
    useState<ShippingAddressType>(null);
  const [pickUpAddress, setPickUpAddress] = useState("");

  const freightOption: FreightOption = useSelector(
    (state: RootState) => state.orders.freightOption
  );

  const currentOrder: CurrentOrder = useSelector(
    (state: RootState) => state.orders.currentOrder
  );

  const currentOrderShipAddress: Address = useSelector(
    (state: RootState) => state.orders.currentOrderShipAddress
  );

  const dispatch = useDispatch();
  const history = useHistory();

  const updateShippingAddressInRedux = useCallback(
    (address: Address | null) => {
      dispatch(setCurrentOrderShipAddress(address));
    },
    [dispatch]
  );

  const handleShippingAddressOptionChange = (option: ShippingAddressType) => {
    setShippingAddressType(option);

    if (option === "Pick Up") {
      const address = {
        is_primary_address: false,
        address_city: "Burnaby",
        address_contact: null,
        address_country: "CANADA",
        address_fax: "",
        address_line1: "7898 North Fraser Way",
        address_line2: "",
        address_line3: "",
        address_line4: "",
        address_phone: "",
        address_postcode: "V5J0C7",
        ship_code: null,
        ship_to_name: "CUSTOMER PICKUP",
        state: "BC"
      };
      updateShippingAddressInRedux(address);

      setPickUpAddress(FASHION_BIZ_PICK_UP_ADDRESS);
      dispatch(updateFreightOption("pickup"));
    } else {
      setPickUpAddress("");
      updateShippingAddressInRedux(null);
      dispatch(updateFreightOption(null));
    }
  };

  function _renderShippingAddressForm() {
    switch (shippingAddressType) {
      case "Primary Address":
        return (
          <PrimaryAddress
            updateShippingAddressInRedux={updateShippingAddressInRedux}
          />
        );
      case "Address Book":
        return (
          <AddressBook
            updateShippingAddressInRedux={updateShippingAddressInRedux}
          />
        );
      case "New Address":
        return (
          <NewAddress
            updateShippingAddressInRedux={updateShippingAddressInRedux}
          />
        );
      case "Pick Up":
        return <PickUp address={pickUpAddress} />;
      default:
        return <></>;
    }
  }

  const getRatesForRegion = () => {
    if (currentOrderShipAddress?.state?.toUpperCase() === "BC") {
      return bcRates;
    } else if (currentOrderShipAddress?.state?.toUpperCase() === "AB") {
      return albertaRates;
    } else {
      return otherRatesGround;
    }
  };

  const renderShippingAlert = () => {
    const freightRates = getRatesForRegion();

    return (
      <Alert>
        <p>
          <b>
            Current Order (before tax) : ${currentOrder.total_amount_before_tax}
          </b>
          <span className="bar">|</span>
          <b>Order Units: {currentOrder.total_units}</b>
          <br />
          {freightRates.map((rate, index) => (
            <React.Fragment key={index}>
              {`${rate.key} = ${rate.value}`}
              {index !== freightRates.length - 1 && (
                <span className="bar">|</span>
              )}
            </React.Fragment>
          ))}
        </p>
        {!(
          shippingAddressType === "New Address" ||
          (currentOrderShipAddress?.state &&
          (currentOrderShipAddress.state.toUpperCase() === "BC" ||
            currentOrderShipAddress.state.toUpperCase() === "AB"))
        ) && (
          <>
            <p>
              Orders with more than 100 units will be shipped by GROUND by
              default.
            </p>
            <p>
              (A further 5% discount will apply if you choose GROUND delivery)
            </p>
          </>
        )}
      </Alert>
    );
  };

  function renderContinueButton() {
    if (shippingAddressType && freightOption) {
      return (
        <>
          {renderShippingAlert()}
          <StyledButton
            primary
            onClick={() => history.push("/check-out/payment-selection")}
          >
            Continue
          </StyledButton>
        </>
      );
    }
  }

  return (
    <div className="shipping-page">
      <h4>Where would you like your order shipped?</h4>
      <AddressOptions
        shippingAddressType={shippingAddressType}
        handleShippingAddressOptionChange={handleShippingAddressOptionChange}
      />
      <div className="shippingAddressForm">{_renderShippingAddressForm()}</div>
      {shippingAddressType &&
        freightOption !== "pickup" &&
        currentOrderShipAddress && (
          <div className="freight-options">
            <FreightOptions />
          </div>
        )}

      {renderContinueButton()}
    </div>
  );
}

export default ShippingOptionsCA;
