import { useContext, useEffect, useState } from "react";
import { OrderDetail } from "../../../../interfaces/interfaces";
import AuthenticationHOC from "../../../../components/AuthenticationHOC/AuthenticationHOC";
import { AlertContent } from "../../../../interfaces/interfaces";
import CartItemTable from "../../../../components/CartItemTable/CartItemTable";
import LoadingSpinner from "../../../../components/LoadingSpinner/LoadingSpinner";
import PDFDownloadButton from "../../../../components/PDFDownloadButton/PDFDownloadButton";
import Toast from "../../../../components/Toast/Toast";
import OrderHistoryPDF from "../../../../components/PDFTemplates/Templates/OrderHistoryPDF/OrderHistoryPDF";
import {
  Container,
  StyledButton,
  Wrapper,
} from "../../../../components/styled/styled-components";
import {
  IPaymentSessionResponse,
  getPaymentMethodSession,
} from "../../fetch/paymentMethod";
import {
  getPaymentDetails,
  getPaymentDetailsPaid,
} from "../../../../utils/OrderPaymentDetails";

import PaymentLinkModal from "../PaymentLinkModal";
import { Context, IContext } from "../../../../context/ContextApp";
import { PaymentOption } from "../../../check-out/payment-selection/sendPaymentSelection";
import BankTransferInfoModal from "../../../../components/PaymentInfo/BankTransferInfoModal";
import EmailTransferInfoModal from "../../../../components/PaymentInfo/EmailTrasnferInfoModal";
import getOrder from "../../fetch/getOrder";
import { renderPayment } from "./renders";

enum ResponseStatusTypes {
  "error",
  "success_unpaid",
  "success_paid",
  "success_confirming",
  "success_unavailable",
}

interface Props {
  orderId: number;
  orderAccpacNo: number;
  generateAuthHeader: any;
}

function OrderHistoryDetails({
  generateAuthHeader,
  orderId,
  orderAccpacNo,
}: Props) {
  const [orderStatus, setOrderStatus] = useState("");
  const [paymentSessionUrl, setPaymentSessionUrl] = useState<string | null>(
    null
  );
  const [responseStatus, setResponseStatus] =
    useState<ResponseStatusTypes | null>(null);
  const [toast, setToast] = useState<AlertContent | null>(null);
  const { appBranch }: IContext = useContext(Context);
  const [order, setOrder] = useState<OrderDetail>();
  const [showModal, setShowModal] = useState<boolean>(false);
  const paymentDetails =
    order?.payment_status === "paid"
      ? getPaymentDetailsPaid(order, appBranch)
      : order
      ? getPaymentDetails(order, appBranch)
      : [];
  const [isBankTransferModalOpen, setIsBankTransferModalOpen] = useState(false);
  const [isEmailTransferModalOpen, setIsEmailTransferModalOpen] =
    useState(false);

  useEffect(() => {
    const loadOrderStatus = async () => {
      if (order && order.status.toLowerCase() === "invoiced") return setOrderStatus("Invoiced");

      const url = `${process.env.REACT_APP_HALFBACK_ROOT_API}/orders/order_status?order_accpac_no=${orderAccpacNo}`;

      const res = await fetch(url, generateAuthHeader());
      const data = await res.json();

      try {
        if (data.status === "success") {
          setOrderStatus(data.result.status);
        } else {
          setOrderStatus("Submitted");
        }
      } catch (error) {
        // if (error.response.status === "401") {
        //   alert("Your session is expired. Please login again.");
        //   return <Redirect to="/" />;
        // }

        setOrderStatus("Submitted");
      }
    };

    const loadOrder = async () => {
      const response = await getOrder(orderId);

      if (response.status === 200 && response.order) {
        setOrder(response.order);
      }
    };
    const loadPaymentSession = async () => {
      const redirection_url = `${window.location.origin}/order-history`;
      const responseSession: IPaymentSessionResponse =
        await getPaymentMethodSession(orderId, redirection_url);
      if (
        responseSession.status === 200 &&
        responseSession.session_url !== null
      ) {
        setResponseStatus(ResponseStatusTypes.success_unpaid);
        setPaymentSessionUrl(responseSession.session_url);
        return;
      }
      if (
        responseSession.status === 200 &&
        responseSession.session_url === null
      ) {
        const paymentStatusAction = {
          unpaid: null,
          paid: ResponseStatusTypes.success_paid,
          unavailable: ResponseStatusTypes.success_unavailable,
          confirming: ResponseStatusTypes.success_confirming,
          default: ResponseStatusTypes.success_confirming,
        };
        const statusType =
          paymentStatusAction[responseSession.payment_status || "default"];

        setResponseStatus(statusType);
        setPaymentSessionUrl(null);
        return;
      }
      setResponseStatus(ResponseStatusTypes.error);
    };

    const loadOrderInfo = async () => {
      await loadOrder();
      await loadOrderStatus();
      await loadPaymentSession();
    };

    if (!order && !orderStatus && !responseStatus) loadOrderInfo();
  }, [
    generateAuthHeader,
    responseStatus,
    order,
    orderId,
    orderStatus,
    orderAccpacNo,
  ]);

  function renderOrderStatus() {
    if (!orderStatus) return <LoadingSpinner />;

    try {
      return (
        <p className="order-status">
          {orderStatus.replace(/([A-Z])/g, " $1").replace(/^./, function (str) {
            return str.toUpperCase();
          })}
        </p>
      );
    } catch (err) {
      return <p className="order-status">orderStatus</p>;
    }
  }

  function renderTrackingNumbers() {
    if (order && order.track_numbers.length === 0) return <div>N/A</div>;

    return (
      order &&
      order.track_numbers.map((number: any) => {
        if (number.trackingNumber.includes("NOW COURIERS")) {
          const barcode = number.trackingNumber.substring(13);
          const trackingURL =
            "https://www.nowcouriers.co.nz/now/servlet/ITNG_TAndTServlet?page=1&VCCA=Enabled&Key_Type=CustomerLabel&customer_number=FASHBIZ&consignment_id=" +
            barcode;

          return (
            <div key={number.trackingNumber}>
              <a
                href={trackingURL}
                style={{ textDecoration: "underline" }}
                target="_blank"
                rel="noopener noreferrer"
              >
                Click here to track {number.trackingNumber}
              </a>
            </div>
          );
        } else {
          return (
            <div key={number.trackingNumber}>
              <a
                href={number.trackingUrl}
                style={{ textDecoration: "underline" }}
                target="_blank"
                rel="noopener noreferrer"
              >
                Click here to track {number.trackingNumber}
              </a>
            </div>
          );
        }
      })
    );
  }

  if (!order) return (
      <Wrapper style={{ padding: "10px" }}>
        <LoadingSpinner />
      </Wrapper>
    );

  return (
    <div className="store-order-detail">
      {isBankTransferModalOpen && (
        <BankTransferInfoModal
          onCloseModal={() => setIsBankTransferModalOpen(false)}
        />
      )}
      {isEmailTransferModalOpen && (
        <EmailTransferInfoModal
          onCloseModal={() => setIsEmailTransferModalOpen(false)}
        />
      )}
      <div className="upper">
        <div className="upper-row">
          <table>
            <thead>
              <tr>
                <th>Order Status</th>
                <th>Tracking Info</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{renderOrderStatus()}</td>
                <td>{renderTrackingNumbers()}</td>
              </tr>
            </tbody>
          </table>

          {showModal && (
            <PaymentLinkModal
              orderId={orderId}
              setShowModal={setShowModal}
              setToast={setToast}
            />
          )}

          <div className="group-buttons">
            <PDFDownloadButton
              document={<OrderHistoryPDF order={order} appBranch={appBranch} />}
            >
              <StyledButton>Print</StyledButton>
            </PDFDownloadButton>
            {!responseStatus && (
              <Wrapper style={{ margin: "0 1rem" }}>
                <LoadingSpinner />
              </Wrapper>
            )}
            <div>
              {renderPayment(
                responseStatus,
                paymentSessionUrl,
                toast,
                setToast
              )}
            </div>
            {order.payment_method === PaymentOption.BankTransfer && (
              <div className="pay-order-button">
                <StyledButton onClick={() => setIsBankTransferModalOpen(true)}>
                  Bank Transfer Info
                </StyledButton>
              </div>
            )}
            {order.payment_method === PaymentOption.EmailTransfer && (
              <div className="pay-order-button">
                <StyledButton onClick={() => setIsEmailTransferModalOpen(true)}>
                  Bank Transfer Info
                </StyledButton>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="lower">
        <div className="lower-row">
          {order.ship_to_address && (
            <div className="ship-to">
              <p className="title">Ship To:</p>
              <p>{order.ship_to_address}</p>
            </div>
          )}

          {order.comment && (
            <div className="comment">
              <p className="title">Comment</p>
              <p>{order.comment}</p>
            </div>
          )}
        </div>

        <CartItemTable items={order.order_items} bo split checkout />
        <div className="order-costs">
          <div className="order-costs-row">
            <Container className="order-costs-column-au">
              {paymentDetails.map((paymentDetail, index) => (
                <Container key={index} row>
                  {paymentDetail.boldTitle ? (
                    <b>{paymentDetail.title}:</b>
                  ) : (
                    `${paymentDetail.title}:`
                  )}
                  <span>{paymentDetail.value}</span>
                </Container>
              ))}
            </Container>
          </div>
        </div>
      </div>
      <Toast content={toast} onClick={() => setToast(null)} />
    </div>
  );
}

export default AuthenticationHOC(OrderHistoryDetails);
