import { generateAuthHeader } from "../../../commonfunctions/authentication";
import { OrderDetail } from "../../../interfaces/interfaces";

export interface IResponse {
  status: number;
  message: string;
  order: OrderDetail | null;
}

const getOrder = async (orderId: number): Promise<IResponse> => {
  try {
    const res = await fetch(
      `${process.env.REACT_APP_HALFBACK_ROOT_API}/orders/order/${orderId}`,
      {
        ...generateAuthHeader(),
      }
    );

    const data: IResponse = await res.json();
    return {
      status: 200,
      message: "ok",
      order: data.order,
    };
  } catch (error) {
    const errorMessage: IResponse = {
      status: 500,
      message: "Something went wrong. Please try reloading the page.",
      order: null,
    };
    return errorMessage;
  }
};

export default getOrder;
