import { useState, useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Toast from "../../../components/Toast/Toast";
import { Address, AlertContent } from "../../../interfaces/interfaces";
import { RootState } from "../../../redux/store";
import { AlertType } from "../../../interfaces/enums";
import IconWrapper from "../../../components/IconWrapper/IconWrapper";
import sendPaymentSelection, { PaymentOption } from "./sendPaymentSelection";
import checkOrderIsAbleToCCPayment from "./checkOrderIsAbleToCCPayment";
import {
  Container,
  StyledButton,
  Wrapper,
} from "../../../components/styled/styled-components";
import { FreightOption } from "../../../interfaces/types";
import {
  setCurrentOrderShipAddress,
  updateFreightOption,
} from "../../../redux/actions/orders";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import Icon from "../../../components/Icon/icon";
import BankTransferInfo from "../../../components/PaymentInfo/BankTransferInfo";
import { Context, IContext } from "../../../context/ContextApp";
import EmailTransferInfo from "../../../components/PaymentInfo/EmailTransferInfo";
import { subdomainTypes } from "../../../context/availableSubdomains";

const paymentChargeInfo = {
  au: "VISA/Mastercard - Surcharge 0.88% + GST",
  nz: "VISA/Mastercard - Transaction Surcharge 2.7% + NZ$0.30",
  ca: "VISA/Mastercard - Transaction Surcharge 2.7% + CA$0.03",
};

const PaymentSelection = () => {
  const [paymentOption, setPaymentOption] = useState<PaymentOption | null>(
    null
  );
  const { appBranch }: IContext = useContext(Context);
  const [isOnlineOptionActive, setIsOnlineOptionActive] = useState(false);
  const [isOrderAvailableToCCPayment, setIsOrderAvailableToCCPayment] =
    useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const { push } = history;
  const currentOrder = useSelector(
    (state: RootState) => state.orders.currentOrder
  );
  const [toast, setToast] = useState<AlertContent | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const freightOption: FreightOption = useSelector(
    (state: RootState) => state.orders.freightOption
  );

  const currentOrderShipAddress: Address = useSelector(
    (state: RootState) => state.orders.currentOrderShipAddress
  );

  useEffect(() => {
    const CODVerification = async () => {
      const response = await checkOrderIsAbleToCCPayment();
      if (!response.isAvailable) {
        push("/check-out/order-review");
      }
      setIsOrderAvailableToCCPayment(true);
      setIsOnlineOptionActive(response.isAvailable);
    };

    if (!currentOrder || currentOrder.order_items.length === 0) {
      push("/store/cart");
    }
    if (!isOrderAvailableToCCPayment) CODVerification();
    dispatch(setCurrentOrderShipAddress(currentOrderShipAddress));
    dispatch(updateFreightOption(freightOption));
  }, [
    currentOrder,
    push,
    currentOrderShipAddress,
    freightOption,
    dispatch,
    isOrderAvailableToCCPayment,
  ]);

  const handleOnContinue = async () => {
    setIsLoading(true);
    if (paymentOption) {
      const res = await sendPaymentSelection(paymentOption);
      if (res.status !== 201) {
        setToast({
          message: `Error to select payment, please try again.`,
          type: AlertType.Error,
        });
        setIsLoading(false);
        return;
      }

      setIsLoading(false);
      dispatch(setCurrentOrderShipAddress(currentOrderShipAddress));
      dispatch(updateFreightOption(freightOption));
      return push("/check-out/order-review");
    }
  };

  if (!isOrderAvailableToCCPayment) {
    return (
      <div style={{ margin: "10px" }}>
        <Wrapper>
          <LoadingSpinner />
        </Wrapper>
      </div>
    );
  }

  return (
    <div className="payment-selection-page">
      <h4>Payment method</h4>

      <Container row margin="1em 1em 1em 0">
        {isOnlineOptionActive && (
          <div style={{ marginRight: "1rem" }}>
            <IconWrapper
              text="Visa/Mastercard"
              isMultiChoice
              role="onlineSelection"
              isSelected={paymentOption === PaymentOption.payOnline}
              onClick={() => setPaymentOption(PaymentOption.payOnline)}
            >
              <Icon name="OnlinePaymentIcon" iconWidth="1.5rem" />
            </IconWrapper>
          </div>
        )}

        <IconWrapper
          text="Bank Transfer"
          isMultiChoice
          role="bankTransferSelection"
          isSelected={paymentOption === PaymentOption.BankTransfer}
          onClick={() => setPaymentOption(PaymentOption.BankTransfer)}
        >
          <Icon name="BankTransferIcon" iconWidth="1.5rem" />
        </IconWrapper>

        {appBranch === subdomainTypes.CA && (
          <div style={{ marginLeft: "1rem" }}>
            <IconWrapper
              text="Interac e-Transfer"
              isMultiChoice
              role="emailTransferSelection"
              isSelected={paymentOption === PaymentOption.EmailTransfer}
              onClick={() => setPaymentOption(PaymentOption.EmailTransfer)}
            >
              <Icon name="MailOpened" iconWidth="1.5rem" />
            </IconWrapper>
          </div>
        )}
      </Container>

      {paymentOption === PaymentOption.payOnline && (
        <div className="paymentTableWrapper">
          <table className="paymentTable">
            <tbody>
              <tr>
                <th>Note: </th>
                {appBranch && <td>{paymentChargeInfo[appBranch]}</td>}
              </tr>
            </tbody>
          </table>
        </div>
      )}

      {paymentOption === PaymentOption.BankTransfer && <BankTransferInfo />}
      {paymentOption === PaymentOption.EmailTransfer && <EmailTransferInfo />}

      {paymentOption !== null && (
        <StyledButton
          role="continueButton"
          primary
          disabled={isLoading}
          onClick={() => !isLoading && handleOnContinue()}
        >
          {isLoading ? "Loading..." : "Continue"}
        </StyledButton>
      )}
      <Toast content={toast} onClick={() => setToast(null)} />
    </div>
  );
};

export default PaymentSelection;
