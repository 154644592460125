import { generateAuthHeader } from "../../../commonfunctions/authentication";
import { OrderDetail } from "../../../interfaces/interfaces";

export interface IResponse {
  status: number;
  message: string;
  orders: OrderDetail[] | null;
  total_count: number;
}
export interface Params {
  order_id: string | null;
  po_number: string | null;
  page: number | null;
}

const formatParams = (params: Params): string => {
    let stringParams = ''
    let firstParam = true;
    for (const key in params) {
        if (params[key as keyof Params] !== undefined) {
            if(firstParam) {
                 firstParam = false;
                 stringParams += "?";
            } else {
                 stringParams += "&";
            }
            stringParams += `${key}=${params[key as keyof Params]}`
         }
     }
     return stringParams;
 }

const getOrders = async (params: Params): Promise<IResponse> => {
  const orderParams = formatParams(params);

  try {
    const res = await fetch(`${process.env.REACT_APP_HALFBACK_ROOT_API}/orders${orderParams}`,
      generateAuthHeader()
    );

    const data: IResponse = await res.json();
    return {
      status: 200,
      message: "ok",
      orders: data.orders,
      total_count: data.total_count
    }

  } catch (error) {
    const errorMessage: IResponse = {
      status: 500,
      message: "Something went wrong. Please try reloading the page.",
      orders: null,
      total_count: 0
    }
    return errorMessage;
  };
}

export default getOrders;
