import axios from "axios";
import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { generateAuthHeader } from "../../../../commonfunctions/authentication";
import { log } from "../../../../commonfunctions/logger";
import AddressBook from "../../../../components/Address/AddressBook/AddressBook";
import {
  Container,
  StyledButton
} from "../../../../components/styled/styled-components";
import { Address } from "../../../../interfaces/interfaces";
import {
  FreightOption,
  ShippingAddressType
} from "../../../../interfaces/types";
import {
  setCurrentOrderShipAddress,
  updateFreightOption
} from "../../../../redux/actions/orders";
import { RootState } from "../../../../redux/store";
import AddressOptions from "./AddressOptions/AddressOptions";
import NewAddress from "./AddressOptions/NewAddress/NewAddress";
import PickUp from "./AddressOptions/PickUp/PickUp";
import FreightOptions from "./FreightOptions";

const ShippingOptionsNZ = () => {
  const FASHION_BIZ_PICK_UP_ADDRESS = "11 Hautu Drive, Wiri, Auckland 2104";
  const [shippingAddressType, setShippingAddressType] =
    useState<ShippingAddressType>(null);
  const [pickUpAddress, setPickUpAddress] = useState("");

  const freightOption: FreightOption = useSelector(
    (state: RootState) => state.orders.freightOption
  );
  const currentOrderShipAddress = useSelector(
    (state: RootState) => state.orders.currentOrderShipAddress
  );

  const dispatch = useDispatch();
  const history = useHistory();

  const updateShippingAddressInRedux = useCallback(
    (address: Address | null) => {
      dispatch(setCurrentOrderShipAddress(address));
    },
    [dispatch]
  );

  function handleShippingAddressOptionChange(option: ShippingAddressType) {
    setShippingAddressType(option);

    if (option === "Pick Up") {
      const address = {
        is_primary_address: false,
        address_city: "Auckland",
        address_contact: null,
        address_country: "NZ",
        address_fax: null,
        address_line1: "11 Hautu Drive",
        address_line2: "",
        address_line3: "",
        address_line4: "Wiri",
        address_phone: null,
        address_postcode: "2104",
        ship_code: null,
        ship_to_name: "CUSTOMER PICKUP",
        state: "NI"
      };
      updateShippingAddressInRedux(address);

      setPickUpAddress(FASHION_BIZ_PICK_UP_ADDRESS);
      dispatch(updateFreightOption("pickup"));
    } else {
      setPickUpAddress("");
      updateShippingAddressInRedux(null);
      dispatch(updateFreightOption(null));
    }
  }

  function saveNewAddress() {
    const data = {
      ...currentOrderShipAddress,
      address_line3: "",
      address_state:
        currentOrderShipAddress.state ?? currentOrderShipAddress.address_state,
      address_country: "NZ"
    };

    delete data.state;
    delete data.is_primary_address;

    axios
      .post(
        `${process.env.REACT_APP_HALFBACK_ROOT_API}/ship_to_addresses`,
        data,
        generateAuthHeader()
      )
      .then(() => {
        history.push("/check-out/payment-selection");
      })
      .catch((error) => {
        log(error, "New address save failed (NZ)");
      });
  }

  function _renderShippingAddressForm() {
    switch (shippingAddressType) {
      case "Address Book":
        return (
          <AddressBook
            updateShippingAddressInRedux={updateShippingAddressInRedux}
          />
        );
      case "New Address":
        return (
          <NewAddress
            updateShippingAddressInRedux={updateShippingAddressInRedux}
          />
        );
      case "Pick Up":
        return <PickUp address={pickUpAddress} />;
      default:
        return <></>;
    }
  }

  function renderContinueButton() {
    if (
      shippingAddressType &&
      freightOption &&
      currentOrderShipAddress &&
      currentOrderShipAddress.ship_to_name
    ) {
      if (shippingAddressType === "New Address") {
        return (
          <Container row>
            <StyledButton
              primary
              onClick={() => history.push("/check-out/payment-selection")}
            >
              Continue
            </StyledButton>
            <StyledButton
              primary
              style={{ marginLeft: "1rem" }}
              onClick={() => {
                saveNewAddress();
              }}
            >
              Save and Continue
            </StyledButton>
          </Container>
        );
      } else {
        return (
          <StyledButton
            primary
            onClick={() => history.push("/check-out/payment-selection")}
          >
            Continue
          </StyledButton>
        );
      }
    }
  }

  return (
    <div className="shipping-page">
      <h4>Where would you like your order shipped?</h4>
      <AddressOptions
        shippingAddressType={shippingAddressType}
        handleShippingAddressOptionChange={handleShippingAddressOptionChange}
      />
      <div className="shippingAddressForm">{_renderShippingAddressForm()}</div>
      {shippingAddressType &&
        freightOption !== "pickup" &&
        currentOrderShipAddress && (
          <div className="freight-options">
            <FreightOptions />
          </div>
        )}
      {renderContinueButton()}
    </div>
  );
};

export default ShippingOptionsNZ;
