import { useState } from "react";
import { OrderDetail } from "../../../interfaces/interfaces";
import OrderHistoryDetails from "./OrderHistoryDetails/OrderHistoryDetails";
import { formatFreightOption } from "./util";

interface Props {
  order: OrderDetail;
  open?: boolean;
}

function OrderHistoryCard({ order, open = false }: Props) {
  const [showOrderDetail, setShowOrderDetail] = useState(open);

  function toggleShowOrderDetail() {
    setShowOrderDetail((prevState) => !prevState);
  }

  function displayAccpacOrderId() {
    if (order.order_id) return <span>{order.order_id}</span>;
    if (order.status) return (
        <span className="status">
          <i className="glyphicon glyphicon-exclamation-sign" />{" "}
          {order.status.toUpperCase()}
        </span>
      );

    return (
      <span className="error">
        <i className="glyphicon glyphicon-exclamation-sign" /> Error
      </span>
    );
  }

  function renderStatusTag() {
    if (order.status === "Invoiced") return <div className="invoiced">Invoiced</div>;
    return <div className="check">Click to Check Status</div>;
  }

  const renderBackOrder = () => {
    const orderItems = order.order_items ? order.order_items : [];

    for (let i = 0; i < orderItems.length; i++) {
      if (orderItems[i].backorder != null) return <div>B/O</div>;
    }
  };
  return (
    <>
      <div
        className={
          showOrderDetail ? "store-order-card open" : "store-order-card"
        }
      >
        <div
          className={showOrderDetail ? "brief open" : "brief"}
          onClick={() => toggleShowOrderDetail()}
        >
          <div className="accpac-order-id">{displayAccpacOrderId()}</div>
          <div className="po-number">PO Number: {order.po_number}</div>
          <div className="freight">
            Freight: {formatFreightOption(order.freight_option)}
          </div>
          <div className="status-tag">{renderStatusTag()} </div>
          <div className="back-order">{renderBackOrder()} </div>
          <div className="submitted">{order.submitted_at}</div>
        </div>
        {showOrderDetail && <OrderHistoryDetails orderId={order.id} orderAccpacNo={order.order_id}/>}
      </div>
    </>
  );
}

export default OrderHistoryCard;
