import { useContext, useState } from "react";
import {
  Container,
  StyledButton
} from "../../../components/styled/styled-components";
import Toast from "../../../components/Toast/Toast";
import { AlertType } from "../../../interfaces/enums";
import { AlertContent } from "../../../interfaces/interfaces";
import { Brand } from "../../../interfaces/types";
import { Context, IContext } from "../../../context/ContextApp";
import { subdomainTypes } from "../../../context/availableSubdomains";
import Icon from "../../../components/Icon/icon";

interface Props {
  stylesheetLink?: string;
  brand: Brand;
  slug: string;
}

const ProductLink = ({ stylesheetLink, brand, slug }: Props) => {
  const [toast, setToast] = useState<AlertContent | null>(null);
  const { appBranch }: IContext = useContext(Context);
  const bizCareNoReturns = ["cst250us", "csp102ul", "csc246u", "ccs250u", "cst346ms", "cst346ls", "cst446ms", "cst446ls", "cst447ls", "csp241ll", "cst245ls", "cst313ls", "cst313ms"];
  const bizCareChristmas2024 = ["cst446ms", "cst446ls", "cst447ls"];
  const dahliaShirts = ["rb365l", "rb366lt"];

  const copyLinkToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(generateProductLink(brand, slug, appBranch));

      setToast({
        message: "Copied link to clipboard!",
        type: AlertType.Success
      });
    } catch (error) {
      setToast({
        message: "Could not copy link to clipboard.",
        type: AlertType.Error
      });
    }
  };

  return (
    <>
      {brand && slug && appBranch && generateProductLink(brand, slug, appBranch) !== "/" && (
        <>
          <Container row jcsb margin="0.5em 0" style={{ width: "100%" }}>
            {bizCareNoReturns.includes(slug)
              && <small style={{ display: "block", width: "100%", color: "red" }}>Sales are final. Returns will not be accepted unless faulty or incorrectly supplied.</small>}
            {bizCareChristmas2024.includes(slug)
              && <small style={{ display: "block", width: "100%", color: "red" }}><u>stock will be dispatched from October 2024</u></small>}
            <h6 style={{ display: "block", width: "100%" }}>Website links:</h6>
            {dahliaShirts.includes(slug)
              && <a style={{ width: "48%" }} href="https://cdn.fashionbiz.com/EcoVero.pdf" target="_blank" rel="noopener noreferrer">
              <StyledButton style={{ width: "100%" }}>
                <Container row jcsa aic>
                  EcoVera
                </Container>
              </StyledButton>
            </a>}
          {stylesheetLink
            ?
            <a
            style={{ width: "100%", marginBottom: '1rem' }}
            href={stylesheetLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            <StyledButton style={{ width: "100%" }}>
              <Container row jcsa aic>
                Open Stylesheet
                <Icon name="ExternalLink" />
              </Container>
            </StyledButton>
          </a>
            :
            <button style={{ width: "100%", marginBottom: '1rem', backgroundColor: '#808080', padding: '0.5rem 0', borderRadius: '4px', color: '#FFFFFF', border: 'transparent' }}>
              <Container row jcsa aic>
                No Stylesheet Exists
              </Container>
            </button>
          }
            <a
              style={{ width: "48%" }}
              href={generateProductLink(brand, slug, appBranch)}
              target="_blank"
              rel="noopener noreferrer"
            >
              <StyledButton style={{ width: "100%" }}>
                <Container row jcsa aic>
                  View
                  <Icon name="ExternalLink" />
                </Container>
              </StyledButton>
            </a>
            <StyledButton
              style={{ width: "48%" }}
              onClick={() => copyLinkToClipboard()}
            >
              <Container row jcsa aic>
                Copy
                <Icon name="Clipboard" />
              </Container>
            </StyledButton>
          </Container>
          <Toast content={toast} onClick={() => setToast(null)} />
        </>
      )}
    </>
  );
};

const generateProductLink = (brand: Brand, slug: string, appBranch: subdomainTypes | '') => {
  if (appBranch === "ca") {
    return `https://www.fashionbiz.ca/product/${brand}/${slug}`;
  } else {
    switch (brand) {
      case "biz-corporates":
        return `https://www.bizcorporates.com/product/${slug}`;
      case "biz-care":
        return `https://www.biz-care.com/product/${slug}`;
      case "syzmik":
        return `https://www.syzmik.com/product/${appBranch}/${slug}`;
      case "biz-collection":
        const url = "https://www.bizcollection";

        if (appBranch === "au") {
          return `${url}.com.au/product/${slug}`;
        } else if (appBranch === "nz") {
          return `${url}.co.nz/product/${slug}`;
        }
        return "/";
      default:
        return "/";
    }
  }
};

export default ProductLink;
