import { useState } from "react";
import { log } from "../../commonfunctions/logger";
import {
  TransactionType,
  transactionTypes,
  TransactionResponseTypes,
  transactionSearchTypes,
  TransactionSearchType,
} from "./types";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import {
  Container,
  StyledButton,
  StyledInput,
  StyledSelect,
  Wrapper,
} from "../../components/styled/styled-components";
import { useDocumentTitle } from "../../hooks/document-title";
import { TransactionSummary } from "../../interfaces/interfaces";
import TransactionCard from "./TransactionCard/TransactionCard";
import { getDateRangeByDays, formatDateToString } from "./util";
import getTransactions from "./getTransactios";
import DatePicker from "../../components/DatePicker/DatePicker";
import getTransactionsByPO from "./getTransactionByPO";
import Tooltip from "../../components/Tooltip/Tooltip";
import ExclamationCirlce from "../../assets/exclamationCircle";

const DEFAULT_DAYS_RANGE = 7;
const MAX_MONTH_RANGE = 2;

const Transactions = () => {
  useDocumentTitle("Previous transactions");
  const [transactionType, setTransactionType] = useState<TransactionType>("All");
  const [transactionSearchType, setTransactionSearchType] = useState<TransactionSearchType>("Search");
  const [transactions, setTransactions] = useState<TransactionSummary[] | null>(
    null
  );
  const [message, setMessage] = useState("No Transactions.");
  const [isLoading, setIsLoading] = useState(false);
  const { startDate: defaultStartDate, endDate: defaultEndDate} = getDateRangeByDays(DEFAULT_DAYS_RANGE);

  const currentDate = new Date();
  const [startDate, setStartDate] = useState<Date>(defaultStartDate);
  const [endDate, setEndDate] = useState<Date>(defaultEndDate);
  const [maxEndDate, setMaxEndDate] = useState<Date>(currentDate);

  const [searchValue, setSearchValue] = useState<string>("");

  const loadTransactions = async () => {
    setIsLoading(true);
    const NZStartDate = formatDateToString(startDate);
    const NZEndDate = formatDateToString(endDate);
    const response = await getTransactions(
      transactionType,
      NZStartDate,
      NZEndDate
    );
    if (response.type == TransactionResponseTypes.SUCCESS) {
      setTransactions(response.transactions || []);
    } else {
      setMessage(response.message || "");
      log(new Error(), "Failed to load transaction");
    }
    setIsLoading(false);
  };

  const loadTransactionsPO = async () => {
    setIsLoading(true);
    const response = await getTransactionsByPO(
      searchValue
    );
    if (response.type == TransactionResponseTypes.SUCCESS) {
      setTransactions(response.transactions || []);
    } else {
      setMessage(response.message || "");
      log(new Error(), "Failed to load transaction");
    }
    setIsLoading(false);
  };

  const onChangeStartDate = (newStartDate: Date) => {
    let newMaxEndDate = new Date(newStartDate);
    newMaxEndDate.setMonth(newStartDate.getMonth() + MAX_MONTH_RANGE);

    if (newMaxEndDate > currentDate) {
      newMaxEndDate = currentDate;
    }

    if (endDate > newMaxEndDate) {
      setEndDate(newMaxEndDate);
    }
    setStartDate(newStartDate);
    setMaxEndDate(newMaxEndDate);
  }

  return (
    <Container padding="1em" className="transactions-page">
      <section className="transactions-container">
        <div>
          <label htmlFor="transactionSearchType">Search Type:</label>
          <StyledSelect
            style={{width: "10%", marginLeft: "1rem"}}
            name="transactionSearchType"
            onChange={(e) =>
              setTransactionSearchType(e.target.value as TransactionSearchType)
            }
          >
            {transactionSearchTypes.map((transactionSearchType) => (
              <option key={transactionSearchType}>{transactionSearchType}</option>
            ))}
          </StyledSelect>
        </div>
      </section>

      {(transactionSearchType === "Search") &&
      <Container row className="transactions-container">
        <div className="transactions-row">
          <div style={{width: "70%"}}>
            <label htmlFor="transactionSearchType">PO Number: </label>
            <StyledInput
              type="text"
              style={{ width: "20%", margin: "0 1rem" }}
              placeholder="Search Your PO Number"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setSearchValue(event.target.value)
              }
              role="searchbox"
              value={searchValue}
              />
          </div>

          <div style={{width: "30%", textAlign: "right"}}>
            {isLoading ? (
              <LoadingSpinner />
            ) : (
              <StyledButton onClick={() => loadTransactionsPO()}>
                  Search Transactions
                </StyledButton>
              )}
          </div>
        </div>
    </Container>
      }

      {(transactionSearchType === "Date Time") &&
      <section className="transactions-options">
        <Wrapper>
          <label htmlFor="transactionType">Transaction Type:</label>
          <StyledSelect
            name="transactionType"
            onChange={(e) =>
              setTransactionType(e.target.value as TransactionType)
            }
          >
            {transactionTypes.map((transactionType) => (
              <option key={transactionType}>{transactionType}</option>
            ))}
          </StyledSelect>
        </Wrapper>

        <Wrapper>
          <label htmlFor="stardate">Start Date:</label>
          <DatePicker
            selectedDate={startDate}
            maxDate={endDate}
            onChange={onChangeStartDate}
          />
        </Wrapper>

        <Wrapper>
          <label htmlFor="enddate">End Start:</label>
          <DatePicker
            selectedDate={endDate}
            maxDate={maxEndDate}
            minDate={startDate}
            onChange={(date) => setEndDate(date)}
          />
          <Tooltip
            tooltipClassName="tooltip"
            content={"There is a limit to the date range you can search by. If you go back too far with the start date, then the end date will change accordingly"}
            position="right"
          >
          <ExclamationCirlce style={{width: "30px", marginLeft: "0.5rem"}} className="plane-svg" />
          </Tooltip>
        </Wrapper>

        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <StyledButton onClick={() => loadTransactions()}>
            Load Transactions
          </StyledButton>
        )}
      </section>}

      <span style={{color: "red"}}> Please Note: Transactions are not in the system until the next day.</span>

      <section>
        {transactions ? (
          <table width="100%">
            <thead>
              <tr>
                <th>Transaction No.</th>
                <th>Type</th>
                <th>Date</th>
                <th>Status</th>
                <th>Order</th>
                <th>Debit</th>
              </tr>
            </thead>
            <tbody>
              {transactions.map((transaction, index) => (
                <tr key={index}>
                  <TransactionCard transaction={transaction} />
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <Wrapper>
            <h5>{message}</h5>
          </Wrapper>
        )}
      </section>
    </Container>
  );
};

export default Transactions;
