const EmailTransferInfo = () => (
  <div className="paymentTableWrapper">
    <p>
      <strong>Interac e-Transfer Payment</strong>
    </p>
    <br />
    <p>
      Please make your payment via Interac e-Transfer after submitting this
      order. Your order will be processed and released on receipt of payment
      confirmation.
    </p>
    <table className="paymentTable">
      <tbody>
        <tr>
          <th>
            <strong>Enquiries: </strong>
          </th>
          <td>accounting@fashionbiz.ca</td>
        </tr>
      </tbody>
    </table>
  </div>
);

export default EmailTransferInfo;
