import { getSubdomain } from "../../context/ContextApp";

const appBranch = getSubdomain();
interface Info {
  Name: string;
  Prefix?: string;
  BSB?: string;
  Acc?: string;
  Email: string;
  Beneficiary?: string;
  'Swift Code'?: string;
}

interface LocationByCountry {
  [branch: string]: Info;
}

const locationByCountry: LocationByCountry = {
  au: {
    Name: "Fashion Biz (Aust) P/L",
    BSB: "032 057",
    Acc: "118954",
    Email: "cod@fashbiz.com.au",
  },
  nz: {
    Name: "Fashion Biz Ltd",
    Acc: "03-0187-0354246-090",
    Email: "accountsR@fashionbiz.co.nz",
  },
  ca: {
    Name: "Fashion Biz Wholesale (CAN) Ltd.",
    Beneficiary: "TD Canada Trust A/C # 0902-5322709",
    'Swift Code': "TDOMCATTOR",
    Email: "accounting@fashionbiz.ca",
  },
};

const BankTransferInfo = () => {
  const info = locationByCountry[appBranch as keyof LocationByCountry];
  const infoKeys = info && (Object.keys(info) as Array<keyof Info>);

  return (
    <div className="paymentTableWrapper">
      <p>
        <strong>Bank Transfer Account</strong>
      </p>
      <br />
      <table className="paymentTable">
        {infoKeys &&
          infoKeys.map((key) => (
            <tr>
              <th>{key}:</th>
              <td>
                {key === "Email" ? (
                  <a href={`mailto:${info[key]}`}>{info[key]}</a>
                ) : info[key]}
              </td>
            </tr>
          ))}
      </table>
    </div>
  );
};

export default BankTransferInfo;
